<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="pa-5"
      >
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            Devices
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              class="ml-3"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="devices"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.propertyId="{ item }">
              {{ item.propertyId }}
            </template>
            <template v-slot:item.mainUserEmail="{ item }">
              {{ item.mainUserEmail }}
            </template>
            <template v-slot:item.typeI="{ item }">
              {{ deviceMapper(item.typeI) }}
            </template>
            <template v-slot:item.active="{ item }">
              <v-chip
                dark
                :color="item.active === true ? 'green' : 'red'"
              >
                {{ item.active ? 'ON-LINE' : 'OFF-LINE' }}
              </v-chip>
            </template>
            <template v-slot:item.landlordsDevice="{ item }">
              <v-icon
                v-if="item.landlordsDevice"
                large
                color="green darken-2"
              >
                mdi-check-circle
              </v-icon>
            </template>
            <template v-slot:item.battery="{ item }">
              <v-icon
                v-if="item.battery === 0"
                large
                color="green darken-2"
              >
                mdi-check-circle
              </v-icon>
              <div v-else>
                Less then 20%
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>

export default {
  name: 'AllDevices',
  components: {
  },
  data () {
    return {
      selectedView: '1',
      showProgress: false,
      devices: [],
      search: '',
      headers: [
        { text: 'Type', value: 'typeI' },
        { text: 'Active', value: 'active' },
        { text: 'App Users', value: 'appUserIds' },
        { text: 'Landlord Device', value: 'landlordsDevice' },
        { text: 'Binary', value: 'binaryStatus' },
        { text: 'Battery', value: 'battery' },
        { text: 'Device type', value: 'deviceType' },
        { text: 'Property Id', value: 'propertyId' },
        { text: 'Main user', value: 'mainUserEmail' },
        { text: 'Room Id', value: 'propertyRoomId' },
        { text: 'IEEE (MAC)', value: 'ieee' },
        { text: 'Dev', value: 'devModel' },
        { text: 'Webport Enabled', value: 'webPortEnabled' },
        { text: 'Webport Tag', value: 'webPortTag' }
      ],
      footerProps: {
        'items-per-page-options': [50, 80, 100]
      },
      isMounted: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.pullAllDevices()
    this.isMounted = true
  },
  methods: {
    pullAllDevices () {
      this.showProgress = true
      this.$store.dispatch('getAllDevices')
        .then(resp => {
          if (resp) {
            this.devices = resp.data
            this.showProgress = true
          }
          this.showProgress = false
        })
    },
    deviceMapper (type) {
      let result = ''
      if (type === 'SensorDoorWindow') {
        result = 'Door/Window'
      } else if (type === 'SensorMulti') {
        result = 'Multi'
      } else if (type === 'SensorMotion') {
        result = 'Motion'
      } else if (type === 'SensorWaterLeak') {
        result = 'Leak'
      } else if (type === 'SensorTemperatureOutdoor') {
        result = 'Outdoor Temp'
      } else if (type === 'SmartLightColorDimmable') {
        result = 'Dimmable Bulb'
      } else if (type === 'SmartLightColorChangeable') {
        result = 'Color Bulb'
      } else if (type === 'SensorSmoke') {
        result = 'Smoke'
      } else if (type === 'SensorVibration') {
        result = 'Vibration'
      } else if (type === 'SmartPlug') {
        result = 'Plug'
      } else if (type === 'IASWarning') {
        result = 'Siren'
      } else if (type === 'SensorTemperatureIndoor') {
        result = 'Indoor Temp'
      } else {
        result = 'Unmapped'
      }
      return result
    }
  }
}
</script>

<style scoped>
</style>
