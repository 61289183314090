var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[(_vm.selectedView === '1')?_c('div',{key:"1",staticClass:"pa-5"},[_c('v-card',{attrs:{"elevation":"0","rounded":"xl"}},[_c('v-card-title',[_vm._v(" Devices "),_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"40"}})],1),_c('v-text-field',{staticClass:"ml-3",attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.devices,"search":_vm.search,"footer-props":_vm.footerProps,"loading":_vm.showProgress,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.propertyId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.propertyId)+" ")]}},{key:"item.mainUserEmail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mainUserEmail)+" ")]}},{key:"item.typeI",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.deviceMapper(item.typeI))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.active === true ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.active ? 'ON-LINE' : 'OFF-LINE')+" ")])]}},{key:"item.landlordsDevice",fn:function(ref){
var item = ref.item;
return [(item.landlordsDevice)?_c('v-icon',{attrs:{"large":"","color":"green darken-2"}},[_vm._v(" mdi-check-circle ")]):_vm._e()]}},{key:"item.battery",fn:function(ref){
var item = ref.item;
return [(item.battery === 0)?_c('v-icon',{attrs:{"large":"","color":"green darken-2"}},[_vm._v(" mdi-check-circle ")]):_c('div',[_vm._v(" Less then 20% ")])]}}],null,false,1479479519)})],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }